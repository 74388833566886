import React from 'react';

import Section from '../section';

const SectionAbout = ({ about, email }) => {

  return (
    <Section title="About Me">
        <div className="mb-6">
        {about.map((detail, idx) => {
              return <p className="pb-3" key={idx} >{detail}</p>
        })}
        <p className="pb-3" key={email}>You can reach me at <b className='underline text-gray-900 pb-1'>{email}</b></p>
      </div>
    </Section>
  );
};

export default SectionAbout;
